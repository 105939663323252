import React, { useState } from 'react';
import logo from './logotechdoggroupmetpayoff.png';
import './Navbar.css';
import { Button, Popover, PopoverBody } from 'reactstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';const Navbar = () => {
  const { month, year } = useParams();
  const cookies = new Cookies();
  const now = dayjs(`${year}-${month}`).locale('nl');
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);  const handleChange = (time) => {
    navigate(`/dashboard/${time.month() + 1}/${time.year()}`);
  };  const capitalize = (month) => month.charAt(0).toUpperCase() + month.slice(1);  const logOut = () => {
    cookies.remove('token', { path: '/' });
    navigate('/');
  };  return (
    <div>
      <nav className="d-flex justify-content-between navbar navbar-light bg-white">
          <div className="col-1 col-sm-3 h-100">
            <button className="myButton w-100 h-100" onClick={logOut}>
              Uitloggen
            </button>
          </div>
          <div className="d-flex justify-content-center col-5 col-sm-6 h-100  ">
            <img src={logo} id="logoNav" className="img-fluid m-auto" alt="Logo" />
          </div>

          <div className="d-flex justify-content-end col col-sm-3 h-100 align-middle">
            <Button
              type="button"
              className="h-50 w-0 m-auto ms-0 me-3 me-xxl-4"
              color="secondary"
              id="popoverButton"
              data-bs-toggle="popover"
              data-bs-content="Popup content"
              onClick={() => setPopoverOpen(!popoverOpen)}
            >
              <FontAwesomeIcon icon={faCalendar} className='w-auto h-75' />
            </Button>
            <Popover
              className="bodyPopover"
              placement="bottom"
              isOpen={popoverOpen}
              target="popoverButton"
              toggle={() => setPopoverOpen(!popoverOpen)}
            >
              <PopoverBody className="bodyPopover ps-2">
                <div className="d-flex flex-nowrap justify-content-center">
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/dashboard/${now.month() + 1}/${now.year() - 1}`)
                    }
                    className="btn p-0 me-2"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <h5 className="date m-0">
                    {capitalize(now.format('MMMM'))} {now.year()}
                  </h5>
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/dashboard/${now.month() + 1}/${now.year() + 1}`)
                    }
                    className="btn p-0 ms-2"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MonthPicker
                    className="MonthPicker"
                    date={now}
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </PopoverBody>
            </Popover>
            <div className="month m-auto ms-0 me-4" onClick={() => setPopoverOpen(!popoverOpen)}>
              <p className="MonthShow rounded-2 mb-0 border-4 p-1">
                {now.format('MMM').toUpperCase()}
              </p>
            </div>
          </div>

      </nav>
    </div>
  );
};export default Navbar;